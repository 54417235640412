import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {Link} from "react-router-dom";
import gsap from 'gsap';
import SoftwareIcon from '../assets/img/icons/software_icon.svg';
import WebDevIcon from '../assets/img/icons/web_dev.svg';
import ConsultingIcon from '../assets/img/icons/IT_consulting.svg';

const ServicesSection = () => {
  const [cardHeights, setCardHeights] = useState([]);
  const cardsRef = useRef([]);
  const overlayRefs = useRef([]);

  useEffect(() => {
    setCardHeights(cardsRef.current.map(card => card.getBoundingClientRect().height + 10));
  }, []);

  const handleMouseEnter = (idx) => {
    gsap.to(overlayRefs.current[idx], { y: `-${cardHeights[idx]}px`, duration: 0.5 });
  };

  const handleMouseLeave = (idx) => {
    gsap.to(overlayRefs.current[idx], { y: '0%', duration: 0.5 });
  };

  const cardsData = [
    {
      icon: SoftwareIcon,
      title: 'Software & Systems',
      text: 'Our seasoned software development team is committed to creating innovative software solutions.',
      additionalInfo: 'More information about Software & Systems...',
      anchor: '/services#software-systems',
    },
    {
      icon: WebDevIcon,
      title: 'Web Development',
      text: 'Enhance your online presence with a custom, elegant, mobile-responsive website.',
      additionalInfo: 'More information about Web Hosting & Development...',
      anchor: '/services#webdev',
    },
    {
      icon: ConsultingIcon,
      title: 'Technology Consulting',
      text: 'Add value to your business with infrastructure and security audits, or finding ways to refine your digital presence.',
      additionalInfo: 'More information about Technology Consulting...',
      anchor: '/services#it-consulting',
    },
  ];

  if (cardsRef.current.length !== cardsData.length) {
    cardsRef.current = Array(cardsData.length).fill().map((_, i) => cardsRef.current[i] || React.createRef());
    overlayRefs.current = Array(cardsData.length).fill().map((_, i) => overlayRefs.current[i] || React.createRef());
  }

  return (
    <Container>
      <h1 className='text-center' style={{paddingBottom: '1rem'}}>How We Can Help You</h1>
      <Row xs={1} md={3} className="g-4">
        {cardsData.map((card, idx) => (
        <Col key={idx} style={{zIndex:20}}>
          <Card
            ref={el => cardsRef.current[idx] = el}
            className="h-100 text-center position-relative"
            style={{ cursor: 'pointer', overflow: 'hidden' }}
            onMouseEnter={() => handleMouseEnter(idx)}
            onMouseLeave={() => handleMouseLeave(idx)}
          >
            
            <Card.Body>
              <Card.Title className="card-title">{card.title}</Card.Title>
              <Card.Img variant="top" src={card.icon} className="home-card-img-custom" />
              <Card.Text className="card-text">{card.text}</Card.Text>
            </Card.Body>
            <Link to={card.anchor}>
              <div
                ref={el => overlayRefs.current[idx] = el}
                className="card-overlay"
              >
                <div>{card.additionalInfo}</div>
              </div>
              </Link>
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
);
};

export default ServicesSection;
import React from 'react';
import { Link } from 'react-router-dom'; // If using React Router
import '../styles/styles.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p className="color-white">© 2023 Upstart IT Consulting</p>
        <Link to="/terms" className="footer-link">Terms of Service</Link> {/* Using React Router's Link */}
        {/* If you're not using React Router, you can use a regular anchor tag:
            <a href="/terms" className="footer-link">Terms of Service</a>
        */}
      </div>
    </footer>
  );
};

export default Footer;

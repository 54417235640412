import React from 'react';
import { leapfrog } from 'ldrs'

leapfrog.register()

const LoadingSplash = () => (
  <div className='loading-splash'>
    <l-leapfrog
    size="65"
    speed="2.3" 
    color="white" 
    ></l-leapfrog>
  </div>
);

export default LoadingSplash;
// Example array of detailed descriptions
const itServicesDescriptions = [
    {
      title: 'Technology Audit',
      paragraphs: [
        "Upstart IT's Technology and Vendor Audit service is designed to optimize your business's technological infrastructure and vendor relationships, ensuring efficiency, cost-effectiveness, and alignment with your strategic goals. In a landscape filled with myriad technology solutions and vendor options, it's crucial to have a tech stack and vendor partnerships that truly serve your business needs without unnecessary redundancies or inefficiencies.",

        "Our comprehensive audit process involves a deep dive into your existing technology stack and vendor relationships. We assess the effectiveness and relevance of each component and service, evaluating whether they meet your current and future business requirements. Our goal is to identify areas where your technology stack may be streamlined, and where custom solutions or internal capabilities could potentially replace vendor services, leading to cost savings and increased control over your technology environment.",

        "We also scrutinize the performance and cost-effectiveness of your current vendor relationships. This includes analyzing contracts, service level agreements (SLAs), and the overall value delivered by each vendor. Our aim is to ensure that you are not only getting the best technological solutions but also the best return on your investment.",

        "Our team brings a holistic perspective, considering not just the technical aspects but also how technology and vendor choices impact your business operations and strategic objectives. We provide you with a detailed report, highlighting areas of improvement, potential savings, and recommendations for a more efficient and effective technology ecosystem.",

        "With Upstart IT's Technology and Vendor Audit service, gain the insights and guidance needed to make informed decisions about your technology investments. Streamline your tech stack, optimize vendor relationships, and position your business for sustainable growth and success."
      ],
    },
    {
      title: 'System Simplification',
      paragraphs: [
        "Upstart IT's System Simplification service is strategically focused on reducing your business's technical debt by directly modifying and enhancing your existing technology and code. In an era where complex systems can hinder agility and efficiency, our service aims to streamline your technological infrastructure, making it more manageable, efficient, and future-proof.",

        "Our expert team engages in a hands-on approach, delving into your systems to identify complexities, redundancies, and outdated practices that contribute to technical debt. Once these areas are pinpointed, we actively rework and refine your technology and code. This process includes code refactoring, consolidating redundant processes, updating or replacing legacy systems, and implementing more efficient algorithms and architectures.",

        "Our approach is not just about simplification; it's also about optimization. We ensure that while simplifying the systems, we enhance their performance, scalability, and maintainability. Our solutions are designed to be robust yet flexible, supporting your business's current operations while also accommodating future growth and changes.",

        "Throughout this process, we maintain a keen focus on minimizing disruption to your day-to-day operations. We work collaboratively with your team, ensuring a smooth transition and providing the necessary training and support for any new or modified systems.",

        "The outcome of Upstart IT's System Simplification service is a leaner, more agile technology infrastructure, free of unnecessary complexities that have long-term cost benefits. By reducing technical debt, we not only improve the current performance but also pave the way for easier upgrades and adaptations as technology evolves.",
      ],
    }
  ];

  export default itServicesDescriptions;
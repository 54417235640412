import React, { useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import { Button } from 'react-bootstrap';
import { AiFillFire, AiOutlineClose } from "react-icons/ai";
import softwareServiceDescriptions from '../data/softwareServiceDescriptions.js';
import { HashLink } from 'react-router-hash-link';
import '../styles/styles.css'; // Updated CSS file


const services = [
  // Add your services here
  { title: 'Software Automation', description: "Enhance your business processes by automating redundancy.", icon: 'software_automation.svg' },
  { title: 'Integrations & API Development', description: "We'll help you seamlessly integrate third-party software.", icon: 'integration_api.svg' },
  { title: 'Source Code Audit', description: 'We can help you remove technical debt and ensure source-code scalablitity.', icon: 'audit.svg' }
];

const SoftwareSystems = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [icons, setIcons] = useState({});
  const overlayRef = useRef(null);

  const openOverlay = (service) => {
    setSelectedService(service);
  };

  const closeOverlay = () => {
    setSelectedService(null);
  };

  const handleContactClick = () => {
    closeOverlay();
    // Delay the navigation to give time for the overlay to close
    setTimeout(() => {
      window.location.hash = 'services#contact';
    }, 300);
  };

  const getDetailedDescription = (title) => {
    return softwareServiceDescriptions.find(desc => desc.title === title);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the overlay is open and the clicked target is not within the overlay-content
      if (selectedService && overlayRef.current && !overlayRef.current.contains(e.target)) {
        closeOverlay();
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside);
    
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    }
  }, [selectedService]); // Only re-run if selectedService changes

  useEffect(() => {
    const loadIcons = async () => {
      const loadedIcons = {};
      for (const service of services) {
        loadedIcons[service.icon] = (await import(`../assets/img/icons/${service.icon}`)).default;
      }
      setIcons(loadedIcons);
    };

    loadIcons();
  }, []);

  return (
    <div style={{paddingTop: '5rem'}}>
      <h1 className="text-center">Software & Systems</h1>
      <div className="software-services-grid-container">
        {services.map((service, index) => (
          <div 
            className="software-services-grid-card" 
            key={index}
            onClick={() => openOverlay(service)}
          >
            {icons[service.icon] && <img src={icons[service.icon]} className='card-img-custom' alt={`${service.title} Icon`} />}  
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
      {selectedService && (
        <div className="overlay">
          <div className="overlay-content" ref={overlayRef}>
            <AiOutlineClose className="overlay-close" onClick={closeOverlay} />
            <div className="overlay-box">
              <h2>{selectedService.title}</h2>
              {getDetailedDescription(selectedService.title).paragraphs.map((para, index) => (
                <p key={index}>{para}</p>
              ))}
              <HashLink to="/services#contact" style={{ textDecoration: 'none'}}>
              <Button 
                className='btn-elegant'
                onClick={handleContactClick}
                >
                  Contact Us
                </Button>
            </HashLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SoftwareSystems;

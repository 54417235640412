// Example array of detailed descriptions
const softwareServicesDescriptions = [
    {
      title: 'Software Automation',
      paragraphs: [
        "Upstart IT's Software Automation service is specifically tailored to streamline your business processes, enhancing efficiency and productivity. In the fast-paced business world, automation is key to staying competitive, reducing manual effort, and optimizing operations. Our service focuses on identifying and transforming repetitive or inefficient business processes into automated workflows, saving time and resources for your organization.","Our team begins with a thorough analysis of your current business processes, identifying areas where automation can bring the most benefit. We then design and implement customized software solutions that seamlessly integrate with your existing systems. Whether it's automating data entry, streamlining customer service operations, or integrating disparate systems for better workflow management, our solutions are designed to fit your unique business needs.","We leverage the latest in automation technologies, including AI and machine learning, to create intelligent systems that not only automate tasks but also learn and adapt over time. This ensures continuous improvement in process efficiency and effectiveness.","Our commitment extends beyond implementation. We provide comprehensive training and support to ensure your team can fully leverage the benefits of the new automated systems. Additionally, we offer ongoing maintenance and updates, ensuring that your automated processes remain cutting-edge and continue to drive business value.","With Upstart IT's Software Automation service, transform the way your business operates. Reduce the burden of manual tasks, enhance accuracy, and free up your team to focus on strategic initiatives that drive growth and innovation.",
      ],
    },
    {
      title: 'Integrations & API Development',
      paragraphs: [
        "Upstart IT's Integrations & API Development service is expertly crafted to enhance your business's connectivity and interoperability. In today's interconnected digital ecosystem, the ability to seamlessly integrate third-party software and platforms into your system architecture is crucial for operational efficiency and offering a cohesive user experience. Our service is designed to bridge the gap between your existing systems and external applications, ensuring a harmonious and efficient digital environment.",
        "Our team specializes in developing robust, scalable APIs that allow for seamless integration of your company's products or software with other systems. This facilitates the creation of a more versatile and accessible digital infrastructure, where your services can be easily utilized by other businesses or applications, expanding your reach and impact in the market.",
        "For businesses looking to integrate third-party vendor software, our approach involves a detailed analysis of your current system architecture, followed by the careful planning and execution of integrations. We ensure these integrations are secure, reliable, and enhance your system's overall functionality, without disrupting existing operations.",
        "Throughout the process, we focus on creating integrations that are not only functional but also optimized for performance and user experience. Our solutions are designed to be scalable, supporting the growth and evolution of your business needs.",
        "With Upstart IT's Integrations & API Development service, unlock the full potential of your digital assets. Enhance your system's capabilities, streamline operations, and provide more value to your customers through sophisticated and seamless integrations.",
      ],
    },
    {
      title: 'Source Code Audit',
      paragraphs: [
        "Upstart IT's Source Code Audit service offers a meticulous and comprehensive examination of your business's source code, ensuring it is robust, secure, and scalable. In a world where software reliability and security are paramount, our service is designed to identify and rectify deficiencies or bugs in your code, safeguarding against vulnerabilities and enhancing overall system performance.",

        "Our expert team of developers delves deep into your source code, employing the latest tools and methodologies to conduct a thorough analysis. We assess the code for potential security risks, adherence to coding standards, and overall quality. By identifying and addressing these issues, we help fortify your software against potential breaches and ensure it is built on a strong and secure foundation.",
        
        "Scalability is another critical focus of our audit. We evaluate your code's architecture and design to ensure it can handle increased loads and future expansions. Our aim is to ensure your software not only meets current needs but is also primed for future growth and development.",
        
        "In addition to security and scalability, we also look for areas where the efficiency of the code can be improved. This includes optimizing algorithms, refactoring code for better maintainability, and ensuring the codebase is clean and well-documented.",
        
        "Following our audit, you receive a detailed report of our findings, along with recommendations for improvements and best practices to implement. Upstart IT's Source Code Audit service is your assurance that your software is not just functioning, but excelling in every aspect - a cornerstone for your business's technological advancement and success.",
      ],
    },
  ];

  export default softwareServicesDescriptions;
import React, { useRef, useEffect } from 'react';
import WebsiteNavbar from '../components/Navbar';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { AiOutlineArrowDown } from 'react-icons/ai';

// Custom components
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer'
import WebDev from '../components/WebDev';
import ITConsulting from '../components/ITConsulting';
import SoftwareSystems from '../components/SoftwareSystems';

// Styles
import '../styles/styles.css';

const Services = () => {
  const webDevRef = useRef(null);
  const softwareSystemsRef = useRef(null);
  const itConsultingRef = useRef(null);
  const contactFormRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (webDevRef.current) {
      gsap.to(webDevRef.current, { opacity: 1, duration: 1 });
    }
  
    const sections = [softwareSystemsRef, itConsultingRef, contactFormRef];
    
    sections.forEach((ref, index) => {
      // Ensure the ref is current before applying animations
      if (ref.current) {
        gsap.fromTo(ref.current,
          { opacity: 0 },
          {
            opacity: 1, // Ensure it animates to opacity 1
            duration: 1,
            scrollTrigger: {
              trigger: ref.current,
              start: 'top center+=100', // Adjust as necessary
              toggleActions: 'play none none none',
            },
          }
        );
      }
    });

      // Handle scrolling to a specific section based on URL hash
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      }
    }
  }, []);

  const scrollToNextSection = () => {
    const sections = [webDevRef.current, softwareSystemsRef.current, itConsultingRef.current, contactFormRef.current, document.querySelector('footer')];
    const currentScroll = window.scrollY;
    
    let nextSectionIndex = sections.findIndex(section => section.offsetTop > currentScroll);

    // If the current section is before WebDev or is WebDev, skip to SoftwareSystems
    if (nextSectionIndex <= 0) {
      nextSectionIndex = 1; // Index of SoftwareSystems section
    }

    const nextSection = sections[nextSectionIndex];
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="services">
      <WebsiteNavbar />
      <div className="scroll-down-arrow" onClick={scrollToNextSection}>
        <AiOutlineArrowDown size={40} /> {/* Adjust size as needed */}
      </div>
      <div id="webdev" ref={webDevRef} className='first-box'><WebDev /></div>
      <div id="software-systems" className="box" ref={softwareSystemsRef}><SoftwareSystems /></div>
      <div id="it-consulting" className="box" ref={itConsultingRef}><ITConsulting /></div>
      <div ref={contactFormRef} className="box" style={{PaddingTop: "100px"}} ><ContactForm /></div>
      <Footer />
    </div>
  );
};

export default Services;

import React, { useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap';
import home_page_image from '../assets/img/homepage_intro_image.gif'
import { gsap } from 'gsap';
import { HashLink } from 'react-router-hash-link';
import '../styles/styles.css'; // Make sure to create a corresponding CSS file for styling

const HomePageIntro = () => {

  const textSectionRef = useRef(null);
  const imageSectionRef = useRef(null);

    useLayoutEffect(() => {
      // Ensure refs are set
      if (textSectionRef.current && imageSectionRef.current) {
          // Text section slides in from the left
          gsap.fromTo(textSectionRef.current, 
              { x: -500, opacity: 0 }, 
              { x: 0, opacity: 1, duration: 4.5, ease: 'power3.out' });

          // Image section slides in from the right
          gsap.fromTo(imageSectionRef.current, 
              { x: 500, opacity: 0 }, 
              { x: 0, opacity: 1, duration: 4.5, ease: 'power3.out' });
      }
  }, []);

  return (
    <div className="homepage">
      {/* Dynamic layered background */}
      <div className="dynamic-background">
        {/* Add your dynamic background layers here */}
      </div>

      <Container>
        <Row className="align-items-center" style={{ minHeight: '50vh' }}>
          {/* Text Half */}
          <Col md={6} ref={textSectionRef} className="text-section d-flex flex-column justify-content-center text-center">
            <h1>Technology Made Simple</h1>
            <p style={{fontSize: "1.2rem"}}>With over 7+ years of experience in full-stack software development, website or webhosting management, and diverse technical consulting experience, Upstart IT engineers provide the highest quality technical consulting services. </p>
            <HashLink to="/#contact" style={{ textDecoration: 'none'}}>
              <Button className="btn-elegant">Contact Us</Button>
            </HashLink>
          </Col>

          {/* Image Half */}
          <Col md={6} ref={imageSectionRef} className="image-section">
            <img src={home_page_image} alt="Professional Image" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePageIntro;

// Example array of detailed descriptions
const webServicesDescriptions = [
    {
      title: 'Full-Stack Website Development',
      paragraphs: [
        "Upstart IT's Full Stack Web Development service offers a comprehensive approach to building and enhancing your online presence. Our team of adept developers specializes in a variety of Content Management Systems (CMS), ensuring that your website not only aligns with your vision but also maximizes the potential of your chosen platform. With a focus on custom-tailored solutions, we provide advanced functionality designed to boost your site's efficiency and offer deeper insights into its performance.",
        "We offer a suite of integrations, including sophisticated analytics and cutting-edge SEO tools, to elevate your website's reach and impact. Our service doesn't just stop at website development; we extend our expertise to help you select and set up the ideal web hosting environment, ensuring optimal performance and reliability. Additionally, we handle the creation and management of your domain's DNS, ensuring a seamless and hassle-free online experience for you and your visitors. Trust Upstart IT to transform your website into a powerful tool that meets your unique business needs and objectives.",
      ],
    },
    {
      title: 'Ongoing Website Modification & Management',
      paragraphs: [
        "Upstart IT's Ongoing Website Modification & Management service is designed to keep your website at the forefront of digital excellence. We understand that a website is not a static entity but an evolving digital asset that reflects your business's growth and changes. Our dedicated team provides continuous updates and enhancements to your website, ensuring it remains fresh, relevant, and aligned with the latest web standards and trends.", "We offer a range of services including content updates, layout redesigns, feature additions, and performance optimizations. Our proactive approach to website maintenance means we don't just respond to your needs; we anticipate them. This includes regular security checks, timely software updates, and backups, ensuring your website's integrity and uptime.", "Our service also covers the analysis of user behavior and website analytics, allowing us to make data-driven recommendations for improvements. We manage all aspects of your website's functionality, from SEO optimization to integrating the latest digital tools and technologies. With Upstart IT, you can focus on your business while we ensure your website continues to be a dynamic, engaging, and effective tool for your brand.",
        // ... more paragraphs as needed
      ],
    },
    {
      title: 'Content Migrations',
      paragraphs: [
        "Upstart IT's Website Content Migration service is tailored to ensure a smooth and efficient transition of your digital content to a new platform or structure. We understand that migrating website content can be a complex and daunting task, which is why our expert team is dedicated to handling every aspect of the process with precision and care.", "Our service encompasses the transfer of all website elements - from text and images to databases and applications. We prioritize maintaining the integrity and functionality of your content throughout the migration process. Our approach includes a thorough analysis of your current website structure, identifying and resolving compatibility issues, and ensuring a seamless integration with your new platform.", "We also focus on minimizing downtime and any potential impact on your website's current user experience. Our team ensures that SEO rankings are preserved, and all redirects are properly set up to maintain your digital footprint. Upstart IT's meticulous planning and execution guarantee a hassle-free content migration, allowing you to transition to your new website with confidence and ease."
      ],
    },
    {
      title: 'Website Audit',
      paragraphs: [
        "Upstart IT's Website Audit service offers a comprehensive analysis of your website to enhance its performance, security, and user engagement. Our detailed audit process is designed to uncover opportunities for improvement and provide actionable insights, ensuring your website not only meets but exceeds industry standards and best practices.", "Our expert team conducts an in-depth evaluation of various aspects of your website. This includes a thorough analysis of the site's architecture, design, and usability, ensuring it delivers an optimal user experience. We assess the website's loading speed and mobile responsiveness, crucial factors for maintaining user engagement and search engine rankings.", "Security is a top priority in our audit process. We rigorously examine your site for vulnerabilities, providing recommendations for robust security enhancements to protect your digital assets and user data. Our SEO assessment ensures your website is fully optimized for search engines, identifying areas for improvement in content, meta tags, and keyword usage.", "Additionally, we evaluate your website's content effectiveness and its alignment with your brand's message and goals. At the end of our audit, you receive a detailed report outlining our findings and practical, prioritized recommendations for enhancements. With Upstart IT's Website Audit service, you can be assured of a thorough, insightful, and transformative assessment to elevate your website to new heights.",
        // ... more paragraphs as needed
      ],
    },
    {
      title: 'Performance & Security',
      paragraphs: [
        "Upstart IT's Website Performance and Security Improvement service is designed to optimize your website's efficiency and fortify its defenses, ensuring a fast, reliable, and secure online presence. In today's digital landscape, the performance and security of your website are paramount not only for user experience but also for maintaining trust and credibility with your audience.","Our service begins with a comprehensive analysis of your website's current performance metrics. We identify areas that can be optimized for faster loading times, smoother navigation, and enhanced responsiveness across all devices. Our team implements advanced caching techniques, optimizes images and files, and fine-tunes the code to ensure your website operates at peak efficiency.","Security is at the forefront of our service. We conduct an exhaustive security audit to detect vulnerabilities and potential threats. Our measures include implementing robust firewalls, securing data transactions, and ensuring compliance with the latest security protocols and standards. We continuously monitor your website for any security breaches, providing immediate response and remediation to protect your digital assets.","Our ongoing performance and security monitoring means you're always ahead of the curve in a rapidly evolving digital environment. We provide regular updates and reports, keeping you informed of your website's status and our continuous efforts to improve it. With Upstart IT, rest assured that your website will not only perform exceptionally but also stand as a bastion of security in the digital realm."
      ],
    }
  ];

  export default webServicesDescriptions;
import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import EdenFarms from '../assets/img/eden_farms.webp';
import SoftwareEng from '../assets/img/icons/software_engineering.svg';
import AboutPic from '../assets/img/will.svg';
import { HashLink } from 'react-router-hash-link';
import { GrMysql } from "react-icons/gr";
import { FaPython, FaLinux, FaWindows, FaApple, FaMobileAlt, FaDatabase, FaWordpress, FaJs, FaHtml5, FaPhp, FaReact, FaFlask, FaCpanel, FaAws, FaDocker } from 'react-icons/fa';

const AnimatedCards = () => {
    const cardsRef = useRef(null);

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    
        const cards = gsap.utils.toArray('.custom-card', cardsRef.current);
        let overlap = 500; // Default overlap for larger screens

        if (window.innerWidth <= 768) {
            overlap = 200;
        }

        // Adjust overlap for smaller screens
        if (window.innerWidth <= 768) {
            overlap = 200; // Reduced overlap for mobile devices
        }
    
        // Initially position the cards in an overlapped manner
        gsap.set(cards, { y: (i, target) => i * -overlap });
    
        // Create a single timeline for all cards
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: ".card1",
                start: "bottom center",
                endTrigger: ".card3",
                end: "top center",
                scrub: 3,
                pin: true,
            }
        });
    
        // Animate each card with stagger
        timeline.to(cards, {
            y: (index) => (index * -overlap) / 50,
            duration: 1,
            ease: "none",
            stagger: 0.1
        });

    }, []);

    

    return (
        <Container ref={cardsRef} className='animated-cards'>
            <div className="cards">
                {['#1C3144', '#5681F0', '#d1d1d1'].map((color, index) => {
                    if (index === 0) {
                      return (
                        <Row key={index} className={`custom-card card${index + 1}`} style={{ backgroundColor: color, zIndex: 4 - index }}>
                            <Col md={6} className="card-media">
                                <img className='mw-100' src={EdenFarms} loading="lazy" alt="Card Image" />
                            </Col>
                            <Col md={6} className="card-content text-section d-flex flex-column justify-content-center text-center">
                                <h2>Full-Stack Web Development</h2>
                                <p style={{textAlign: "justify"}}>At Upstart IT, we simplify the process for individuals and businesses seeking either a new website or a redesign. Our comprehensive services include hosting, website design, development, and ongoing maintenance. Our team boasts over seven years of experience in developing websites across various platforms, content management systems (CMS), and programming languages. Whether you're facing issues with your current website, need bug fixes, or require a site that's both lightweight and feature-rich, we have the expertise to deliver.
                                </p>
                                <HashLink to="/#contact" style={{ textDecoration: 'none'}}>
                                  <Button className='btn-elegant'>Get In Touch</Button>
                                </HashLink>
                            </Col>
                        </Row>
                      );
                    } else if (index === 1) { // Only for the second card
                        return (
                            <Row key={index} className={`custom-card card${index + 1}`} style={{ backgroundColor: color, zIndex: 4 - index }}>
                                <Col md={8} className='animated-cards-lp'>
                                    <p className= 'extra-text' style={{marginBottom: "0"}}>THE RIGHT SOFTWARE MAKES ALL THE DIFFERENCE</p>
                                    <h2>Helping businesses find success through dependable, secure, and efficient software solutions.</h2>
                                    <p>Our expertise in crafting scalable, clean, and secure code lays the groundwork for fast and dependable software. Our approach significantly reduces development time, enabling quicker deployment and faster realization of benefits from your new software solution.</p>
                                    <table className='home-page-table'>
                                        <thead>
                                        <tr>
                                            <th>Languages</th>
                                            <th>Platforms</th>
                                            <th>Databases</th>
                                            <th>Environments</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <ul className='list-unstyled'>
                                                    <li><FaPython /> Python</li>
                                                    <li><FaJs /> Javascript</li>
                                                    <li><FaHtml5 /> HTML/CSS</li>
                                                    <li><FaPhp /> PHP</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className='list-unstyled'>
                                                    <li><FaWordpress /> WordPress</li>
                                                    <li><FaReact /> React</li>
                                                    <li><FaFlask /> Flask</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className='list-unstyled'>
                                                    <li><FaDatabase /> PostgreSQL</li>
                                                    <li><GrMysql /> MySQL</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className='list-unstyled'>
                                                    <li><FaLinux /> Linux</li>
                                                    <li><FaWindows /> Windows</li>
                                                    <li><FaApple /> MacOS</li>
                                                    <li><FaAws /> AWS</li>
                                                    <li><FaDocker /> Docker</li>
                                                    <li><FaCpanel /> cPanel</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </table>
                                </Col>
                                <Col md={4} className="card-media card-content">
                                <img className='mw-100' src={SoftwareEng} alt="Card Image" />
                            </Col>
                            </Row>
                        );
                    } else if (index === 2) { // Only for the second card
                      return (
                          <Row key={index} className={`custom-card card${index + 1}`} style={{ backgroundColor: color, zIndex: 4 - index }}>
                              <Col md={3} className="card-img text-center card-text">
                                <img className='mw-100' src={AboutPic} alt="Card Image" style={{paddingBottom: '10px'}} />
                                <h4 sytle={{marginBottom: '0rem'}}>Will Chanania</h4>
                                <p style={{marginTop:'-10px'}}>Owner and Principal Engineer</p>
                              </Col>
                              <Col md={7} className='card-content text-section d-flex flex-column justify-content-center card-text animated-cards-lp'>
                                <h2 className='text-center'>Consult With People Who Care</h2>
                                <p>The distinction between Upstart IT and its competitors lies in our unique approach: our engineers are as passionate about people as they are about technology. We prioritize attentive listening to genuinely understand the specific needs of your business, fostering a deep and enduring trust. At Upstart IT, we take immense pride in nurturing long-term relationships with our clients, a testament to our commitment and the quality of service we deliver.</p> 
                              </Col>
                          </Row>
                      );
                  }
                })}
            </div>
        </Container>
    );
};

export default AnimatedCards;
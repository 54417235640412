import React from 'react';
import { Container, Carousel, Row, Col } from 'react-bootstrap';
import '../styles/styles.css'; // Assuming your custom styles are here

const TestimonialsSection = ({ testimonials}) => {
  return (
    <Container>
      <h1 className='text-center'>See What Our Clients Have To Say</h1>
      {/* Carousel Ticker for Testimonials */}
      <Carousel className="testimonial-carousel " interval={3000} controls={false}>
        {testimonials.map((testimonial, idx) => (
          <Carousel.Item key={idx}>
            <h3>{testimonial.title}</h3>
            <p>{testimonial.content}</p>
            <p>{testimonial.author}</p>
            {/* <img src={testimonial.logo} alt={`Partner logo ${idx}`} className="partner-logo" /> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default TestimonialsSection;

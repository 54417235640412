import React, { useRef, useEffect } from 'react';
import WebsiteNavbar from '../components/Navbar';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLocation } from 'react-router-dom';

// Custom components
import ServicesSection from '../components/ServicesSection';
import TestimonialsSection from '../components/Testimonials';
import AnimatedCards from '../components/AnimatedCards';
import ContactForm from '../components/ContactForm';
import testimonials from '../data/testimonials';
import Footer from '../components/Footer'
import { SlArrowDown } from "react-icons/sl";

// Styles
import '../styles/styles.css';
import HomePageIntro from '../components/HomePageIntro';

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
    
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const arrow = document.querySelector('.intro-down-arrow-container');
      if (window.scrollY > 50) { // adjust this value as needed
        arrow.classList.add('hide-arrow');
      } else {
        arrow.classList.remove('hide-arrow');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const scrollTo = queryParams.get('scrollTo');
  
    if (scrollTo === 'contact') {
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  

  const main = useRef();

  useGSAP(
    () => {
      const boxes = gsap.utils.toArray('.box');
      boxes.forEach((box) => {
        gsap.to(box, {
          y: 50,
          scrollTrigger: {
            trigger: box,
            start: 'top top',
            end: 'bottom 10%',
            scrub: true,
            // markers: true,
          },
        });
      });
    },
    [{ scope: main }]
  );
  
  


  


  return (
    <div className="home">
      <WebsiteNavbar />
      <div className="box" style={{marginTop: "6rem"}}>
        <HomePageIntro/>
       </div>
       <div className='intro-down-arrow-container'><SlArrowDown className='intro-down-arrow'/></div>
       <div className="box services-section">
       <ServicesSection/>
       </div>
       <AnimatedCards/>
       <div className="box testimonials">
       <TestimonialsSection testimonials={testimonials}/>
       </div>
       <div className="box" id="contact">
       <ContactForm />
       </div>
       <Footer/>
    </div>
  );
};

export default Home;
import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GridSection from '../components/GridSection';
import { FaServer, FaCode, FaNetworkWired } from 'react-icons/fa';
import WebsiteNavbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const Terms = () => {
  useEffect(() => {
    const sections = document.querySelectorAll('.terms-section');

    gsap.registerPlugin(ScrollTrigger);

    sections.forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'Power3.easeInOut',
          scrollTrigger: {
            trigger: section,
            start: 'top center',
            end: 'bottom center',
          },
        }
      );
    });
  }, []);

  return (
    <div className="services">
      <WebsiteNavbar />
      <Container>
        <h1 className="terms-section text-center mb-5">
            <div className='spacer'></div>
          Upstart IT LLC - Terms and Conditions
        </h1>
        <div className="terms-section mb-5">
          <h2 className="mb-3">1. Introduction</h2>

                <p>Welcome to Upstart IT LLC ("Upstart IT"). These Enhanced Terms and Conditions ("Enhanced Terms") govern your interaction and engagement with Upstart IT, including website browsing, service inquiries, consultation requests, and any other form of interaction.</p>

                <p>By accessing or utilizing Upstart IT's services, solutions, or products ("Services"), you ("Client") agree to be bound by these Enhanced Terms. Please read these Enhanced Terms carefully before you start using Upstart IT's Services.</p>

                <h2>2. Definitions</h2>

                <p>To ensure clear understanding, the following terms have specific meanings within these Enhanced Terms:</p>

                <ul>
                    <li>**Client:** Any individual or entity engaging with Upstart IT in any capacity, including website visitors, potential clients, and contracted clients.</li>
                    <li>**Services:** All IT consulting services, solutions, and products offered by Upstart IT to Clients.</li>
                    <li>**Content:** Any information, data, or materials provided by Client to Upstart IT, such as proposals, documents, and user-generated content.</li>
                    <li>**Intellectual Property Rights:** All intellectual property rights owned by Upstart IT, including copyrights, patents, trademarks, trade secrets, and other proprietary rights related to its Services and Content.</li>
                    <li>**Confidential Information:** All non-public information disclosed by either party to the other, designated as confidential or with a reasonable expectation of confidentiality.</li>
                </ul>

                <h2>3. Scope of Services and Interactions</h2>

                <p>Upstart IT offers a diverse portfolio of IT consulting services, solutions, and products tailored to meet the specific needs of each Client. The precise scope of Services and interactions will be outlined in a separate service agreement or proposal, ensuring transparency and clear expectations.</p>

                <p>Upstart IT reserves the right to modify its Services or limit interactions at any time. However, it will provide Clients with reasonable notice of any such changes.</p>

                <h2>4. Fees and Payment</h2>

                <p>Clients agree to pay all applicable fees associated with the Services as outlined in the service agreement or proposal. These fees may include project fees, hourly rates, material costs, and travel expenses. The payment terms are specified in the agreement, and late payments may incur interest and/or late fees.</p>

                <p>Upstart IT reserves the right to modify its fee structure upon reasonable notice to Clients.</p>

                <h2>5. Client Responsibilities</h2>

                <p>Clients have specific responsibilities when engaging with Upstart IT, ensuring efficient and successful service delivery. These responsibilities include:</p>

                <ul>
                    <li>Providing Upstart IT with all necessary information and materials in a timely manner.</li>
                    <li>Ensuring the accuracy and completeness of all information provided.</li>
                    <li>Cooperating with Upstart IT and its personnel to facilitate the efficient delivery of Services.</li>
                    <li>Adhering to all applicable laws and regulations associated with the Services.</li>
                    <li>Obtaining any necessary licenses or permits required for utilizing Upstart IT's Services.</li>
                    <li>Carefully reviewing and approving all deliverables provided by Upstart IT.</li>
                </ul>

                <h2>6. Confidentiality and Data Protection</h2>

                <p>Upstart IT recognizes the importance of confidentiality and data protection. Both parties agree to:</p>

                <ul>
                    <li>Maintain the confidentiality of all Confidential Information exchanged.</li>
                    <li>Implement reasonable security measures to protect Confidential Information from unauthorized access, use, disclosure, alteration, or destruction.</li>
                    <li>Comply with all applicable data protection laws and regulations, including but not limited to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).</li>
                </ul>

                <h2>7. Warranties and Disclaimers</h2>

                <p>Upstart IT warrants that it will perform the Services with reasonable skill, care, and professionalism. However, Upstart IT makes no other warranties, express or implied, with respect to the Services, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. Upstart IT disclaims any liability for any errors, omissions, or inaccuracies in the Services or Content.</p>

                <h2>8. Limitation of Liability</h2>

                <p>Upstart IT shall not be liable for any indirect, incidental, consequential, special, or exemplary damages arising out of or related to the Services, even if Upstart IT has been advised of the possibility of such damages. Upstart IT's total liability for any claim arising out of or related to the Services shall be limited to the amount of fees paid by Client for the Services.</p>

                <h2>9. Termination</h2>

                <p>This Agreement may be terminated by either party upon written notice to the other party. Upstart IT may terminate this Agreement immediately if Client breaches any of its obligations under this Agreement, fails to pay fees, or engages in any activity that is harmful or detrimental to Upstart IT or its reputation.</p>

                <h2>10. Governing Law and Jurisdiction</h2>

                <p>These Enhanced Terms shall be governed by and construed in accordance with the laws of the State of New York, without regard to its conflict of laws principles. Any dispute arising out of or relating to these Enhanced Terms shall be subject to the exclusive jurisdiction of the courts located in Albany County, New York.</p>

                <h2>11. Entire Agreement</h2>

                <p>These Enhanced Terms constitute the entire agreement between the parties with respect to the subject matter hereof and supersede all prior or contemporaneous communications, representations, or agreements, whether oral or written.</p>

                <h2>12. Notices</h2>

                <p>All notices and other communications hereunder shall be in writing and shall be deemed to have been duly given (i) when delivered personally, (ii) upon confirmed receipt by certified or registered mail, postage prepaid, return receipt requested, or (iii) upon confirmed receipt by overnight courier service. Notices shall be addressed to Upstart IT at the following address:</p>

                <p>Upstart IT LLC<br />
                123 Main Street, Suite 456<br />
                Albany, NY 12207</p>

                <p>or to Client at the address set forth in the service agreement or proposal.</p>
                </div>
            </Container>
            <Footer/>
        </div>
    );
};

export default Terms;
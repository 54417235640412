import React from 'react';
import logo from '../assets/img/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { HashLink } from 'react-router-hash-link';

function NavBar() {

  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top" style={{zIndex: 50}}>
      <Container fluid>
        <Navbar.Brand as={HashLink} to="/">
          <img
            id='navbar-logo'
            src={logo}
            width="250"
            height="90"
            className="d-inline-block align-top"
            alt="Upstart IT Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" style={{ paddingRight: '30px' }}>
            <HashLink to="/services" className="nav-link" style={{ textDecoration: 'none', color: 'black' }}>Services</HashLink>
            <HashLink to="#contact" className="nav-link" style={{ textDecoration: 'none', color: 'black' }}>Contact Us</HashLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;

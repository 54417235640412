import cjtr_logo from '../assets/img/cjtr_logo.png';
import eden_logo from '../assets/img/eden_logo.png';
import wngc_logo from '../assets/img/wngc_logo.png';


const testimonials = [
    {
      title: "Excellent Service",
      content: "The team at Upstart IT Consulting provided exceptional service for our project. Their attention to detail and dedication to meeting our needs was outstanding.",
      author: "Danielle Sargenti, CEO of Central Rigging Trucking & Rigging",
      logo: cjtr_logo
    },
    {
      title: "Highly Professional",
      content: "I was thoroughly impressed with the professionalism and expertise displayed by the team at Upstart IT Consulting. They delivered our project on time and exceeded our expectations.",
      author: "Matthew Condon, President of Eden Farms LLC",
      logo: eden_logo
    },
    {
      title: "Outstanding Collaboration",
      content: "Working with Upstart IT Consulting was a great experience. Their collaborative approach made the process smooth and enjoyable, and the end result was exactly what we needed.",
      author: "Lori Budrow, Chair of West Newbury Garden Club",
      logo: wngc_logo
    },
  ];
  
  export default testimonials;
import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();
  const [captchaValidated, setCaptchaValidated] = useState(false);

  const onReCAPTCHAChange = (captchaCode) => {
    if (captchaCode) {
      setCaptchaValidated(true);
    } else {
      setCaptchaValidated(false);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!captchaValidated) {
      alert('Please verify that you are not a robot.');
      return;
    }

    emailjs.sendForm('service_izc3ky8', 'template_mwyto5q', form.current, 'GVSw2LFcn-CAB8d3k')
      .then((result) => {
        console.log(result.text);
        // Reset reCAPTCHA and form after successful submission
        setCaptchaValidated(false);
        form.current.reset();
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <Row className="justify-content-center" style={{ minHeight: '50vh', minWidth: '80vw', paddingBottom: "100px" }}>
      <Col md={5} className="text-section d-flex flex-column justify-content-center">
        <div className="contact-form" id="contact" style={{background: '#4982F8', borderRadius:'20px'}}>
          <h2 className='text-center'>Contact Us</h2>
          <form ref={form} onSubmit={sendEmail}>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" required />

            <ReCAPTCHA className="recaptcha"
              sitekey="6LclwzYpAAAAAGeNnhkkeoxD4vhtpLmenqA8axy-"
              onChange={onReCAPTCHAChange}
            />

            <button type="submit">Send Message</button>
          </form>
        </div>
      </Col>
    </Row>
  );
};

export default ContactForm;
